.whatsapp-button {
  position: fixed;
  bottom: 10px;           /* Distância da parte inferior da tela */
  right: 20px;            /* Distância da parte direita da tela */
  width: 80px;            /* Ajusta a largura do botão */
  height: 100px;           /* Ajusta a altura do botão */
  background-color: #ffffff; /* Cor padrão do WhatsApp */  
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra suave ao redor do botão */
  cursor: pointer;       /* Mostra o ponteiro de clique */
  display: flex;          /* Usamos flexbox para centralizar o conteúdo */
  align-items: center;    /* Centraliza verticalmente o conteúdo */
  justify-content: center; /* Centraliza horizontalmente o conteúdo */
  z-index: 1000;          /* Garante que o botão fique acima de outros elementos */
  transition: all 0.3s ease; /* Animação suave para mudanças de estado */
  
}

.whatsapp-button h2 {
  margin: 0;               /* Remove qualquer margem padrão do <h2> */
  display: flex;           /* Usamos flexbox para controlar o alinhamento */
  justify-content: center; /* Garante que o conteúdo seja centralizado horizontalmente */
  align-items: center;     /* Alinha o texto verticalmente se o <h2> tiver altura maior que o necessário */
  height: 100%;            /* Garante que o h2 ocupe toda a altura disponível */
  text-align: right;      /* Centraliza o texto horizontalmente */

}

.whatsapp-button img {
  width: 90px;
  height: 90px;
  align-items: center;
}

@media (max-width: 768px) {
  .whatsapp-button {
    bottom: 10px;
    right: 10px;
    width: 100px; /* Reduz o tamanho do botão em dispositivos móveis */
    height: 50px; /* Reduz o tamanho do botão em dispositivos móveis */
  }

  .whatsapp-button img {
    width: 30px; /* Reduz o tamanho do ícone em dispositivos móveis */
    height: 30px;
  }
}
