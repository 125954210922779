/* Estilo principal da página */
.politica-container {
    font-family: 'Montserrat', sans-serif;
    color: #333;
    padding: 30px;
    line-height: 1.6;
    max-width: 1200px;
    margin: 0 auto; /* Centraliza o conteúdo */
    margin-top: 160px;
    background-color: #f9f9f9; /* Fundo claro */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Sombra sutil */
    border-radius: 10px; /* Cantos arredondados */
  }
  
  /* Títulos principais */
  .politica-container h1 {
    text-align: center;
    color: #0f8e45; /* Verde da Capitus */
    margin-bottom: 20px;
  }
  
  /* Subtítulos */
  .politica-container h2 {
    color: #0f8e45;
    font-size: 1.5rem;
    margin-top: 20px;
    margin-bottom: 10px;
    border-left: 4px solid #0f8e45; /* Barra decorativa */
    padding-left: 10px;
  }
  
  /* Parágrafos */
  .politica-container p {
    margin-bottom: 15px;
    color: #555; /* Tom levemente mais escuro para o texto */
  }
  
  /* Listas */
  .politica-container ul {
    margin-left: 20px;
    list-style-type: disc;
  }
  
  .politica-container ul li {
    margin-bottom: 10px;
    color: #333;
  }
  
  /* Links */
  .politica-container a {
    color: #0f8e45;
    text-decoration: none;
    font-weight: bold;
  }
  
  .politica-container a:hover {
    text-decoration: underline;
    color: #0c6e36; /* Tom mais escuro de verde */
  }
  
  /* Destaques (negrito, fortes) */
  .politica-container strong {
    color: #333;
    font-weight: bold;
  }
  
  /* Última linha (atualização) */
  .politica-container .ultima-atualizacao {
    margin-top: 20px;
    font-size: 0.9rem;
    color: #777;
    text-align: center;
  }
  