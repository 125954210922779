/* Estilos principais */
body {
  font-family: "Roboto", sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f8f9fa;
  color: #333333;
}

/* Navbar */
.navbar {
  /* ... estilos existentes ... */
}

/* Seção principal */
.home-container {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Alinha o conteúdo ao topo */
  align-items: center;
  color: #fff;
  text-align: center;
  padding: 20px 20px;
  overflow: hidden;
}

.home-container .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); /* Overlay escuro */
  z-index: 1;
}

.home-container .home-content {
  position: relative;
  z-index: 2;
}

.home-container h1 {
  font-size: 2.8rem;
  margin-bottom: 1rem;
  text-shadow: 0 4px 6px rgba(0, 0, 0, 0.6);
  animation: fadeInSlide 1.5s ease-out;
}

.home-container p {
  font-size: 1.3rem;
  margin-bottom: 2rem;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  animation: fadeInSlide 1.5s ease-out;
  animation-delay: 0.5s;
}

.home-container .action-button {
  background-color: #0f8e45;
  color: #fff;
  padding: 12px 24px;
  font-size: 1.1rem;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.home-container .action-button:hover {
  background-color: #0c6d38;
  transform: scale(1.05);
}

/* Seção de Cards */
.cards-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 40px 20px;
  background-color: #f8f9fa;
}

/* Ajuste dos Cards sobre o Background */
.cards-grid {
  position: relative;
  z-index: 2; /* Sobre o fundo */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 50px; /* Move os cards para mais perto do título */
  padding: 0 20px; /* Remove padding desnecessário */
  background: none; /* Remove fundo branco */
  width: 100%;
  max-width: 1200px;
}

/* Estilo dos Cards */
.card {
  background-color: rgba(255, 255, 255, 0.8); /* Fundo translúcido */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra mais sutil */
  border-radius: 12px; /* Bordas mais arredondadas */
  width: 260px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 1px solid rgba(0, 0, 0, 0.1); /* Borda fina para separação */
}

.card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.card-image {
  width: 100%;
  height: 160px;
  object-fit: cover;
  border-bottom: 2px solid #0f8e45; /* Linha decorativa */
}

.card-title {
  font-size: 1.4rem;
  color: #333;
  margin: 15px 0 5px 0;
}

.card-description {
  font-size: 1rem;
  color: #666;
  margin: 0 15px 15px 15px;
  text-align: center;
}

.card .action-button {
  margin-bottom: 20px;
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #0f8e45;
  color: #fff;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.card .action-button:hover {
  background-color: #0c6d38;
}

/* Responsividade */
@media (max-width: 768px) {
  .cards-grid {
    flex-direction: column;
    align-items: center;
  }

  .card {
    width: 90%; /* Aumenta o tamanho no mobile */
  }
}

/* Animação */
@keyframes fadeInSlide {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsividade */
@media (max-width: 768px) {
  .home-container h1 {
    font-size: 2rem;
  }

  .home-container p {
    font-size: 1rem;
  }

  .cards-grid {
    flex-direction: column;
    align-items: center;
  }

  .card {
    width: 80%;
  }
}
