.whatsapp-button {
  position: fixed;
  bottom: 60px;
  right: 20px;
  z-index: 100;
  cursor: pointer;
  background-color: #25d366; /* Verde do WhatsApp */
  border-radius: 50%; /* Forma circular */
  padding: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  border: 3px solid #fff; /* Contorno branco */
  animation: pulse 2s infinite; /* Animação de pulsação */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.whatsapp-button:hover {
  transform: scale(1.1);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3);
}

.whatsapp-button img {
  width: 50px;
  height: 50px;
}                                                                                   

@media (max-width: 768px) {
  .whatsapp-button {
    bottom: 15px;
    right: 15px;
    padding: 10px;
  }
  .whatsapp-button img {
    width: 50px;
    height: 50px;
  }
}
