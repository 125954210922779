/* CotacaoFacilPage.css */
.cotacao-page {
  position: relative;
  width: 100%;
  height: 100vh; /* Ocupa a altura total da tela */
  background-color: #fff;
  color: #eb5114;
  text-align: center;
}

iframe {
  width: 100%;
  height: 100vh; 
  border: none;
  position: relative;
  z-index: 1;
}

