/* Estilo Principal da Página Sobre */
.sobre-container {
    max-width: 1200px;
    margin: 40px auto 40px auto; /* Margem superior para separação do header */
    padding: 20px;
    background-color: #ffffff; /* Fundo branco para contraste */
    border-radius: 8px; /* Bordas arredondadas para suavizar */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra para destaque */
    color: #333; /* Cor do texto para boa legibilidade */
    font-family: "Roboto", sans-serif;
    line-height: 1.6;
  }
  
  /* Título */
  .sobre-container h1 {
    font-size: 1.8rem;
    color: #0f8e45; /* Cor da marca */
    margin-bottom: 20px;
    text-align: center;
  }
  
  /* Parágrafos */
  .sobre-container p {
    font-size: 1.2rem;
    margin-bottom: 20px;
    text-align: justify; /* Justifica o texto para alinhamento mais profissional */
  }
  
  /* Destaques em negrito */
  .sobre-container strong {
    color: #0f8e45; /* Cor da marca para palavras-chave */
  }
  
  /* Responsividade */
  @media (max-width: 768px) {
    .sobre-container {
      margin: 100px 20px; /* Ajuste para telas menores */
      padding: 15px;
    }
  
    .sobre-container h1 {
      font-size: 2rem;
    }
  
    .sobre-container p {
      font-size: 1rem;
      
    }
  }
  /* Estilização Geral do Link */
a {
  margin-left: 2px; /* Adiciona um pequeno espaço antes do link */
  color: #0a66c2;
  text-decoration: none;
}

a:hover {
  color: #004182;
  text-decoration: underline;
}
  
  /* Para Links em Contexto Específico, como na seção "Quem Somos" */
  .sobre-container a {
    font-size: 1rem; /* Ajuste o tamanho da fonte para se alinhar ao texto */
    border-bottom: 2px solid transparent; /* Linha decorativa sutil */
  }
  
  .sobre-container a:hover {
    border-bottom: 2px solid #0a66c2; /* Adiciona linha azul no hover */
    text-decoration: none; /* Remove o sublinhado padrão do hover */
  }
  
  