/* Estilo Principal do Header */
.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 15px 30px; /* Maior espaçamento para respiro */
  position: sticky;
  top: 0;
  z-index: 10;
}

/* Logo */
.logo-container {
  flex: 1;
}

.logo {
  width: 120px; /* Logo levemente maior */
  height: auto;
}


/* Menu Toggle */
.menu-toggle {
  font-size: 2.8rem; /* Ícone levemente maior */
  background: none; /* Remove fundo */
  color: #064420; /* Consistência com o tema */
  border: none;
  cursor: pointer;
  display: none; /* Esconde em telas maiores */
  padding: 10px;
  transition: color 0.3s ease;
  position: absolute; /* Define posição relativa ao container */
  top: 15px; /* Ajusta alinhamento vertical */
  left: 150px; /* Alinha à direita */
}

.menu-toggle:hover,
.menu-toggle:focus {
  background: none; /* Remove qualquer fundo no hover */
  box-shadow: none; /* Remove qualquer sombra no hover */
  outline: none; /* Remove contorno de foco padrão */
  color: #085f2d; /* Define apenas a cor desejada */
}

.menu-toggle:focus {
  outline: none;
}

.menu-toggle:hover {
  color: #085f2d; /* Tom mais escuro no hover */
}

/* Navbar */
.navbar-container {
  flex: 3;
}

.navbar {
  display: flex;
  justify-content: flex-end;
  list-style: none;
  margin: 0;
  padding: 0;
}

.navbar li {
  margin-left: 25px; /* Espaçamento mais equilibrado */
}

.navbar a {
  color: #064420;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  transition: color 0.3s, border-bottom 0.3s ease;
  padding-bottom: 15px; /* Espaço para a linha no hover */
}

.navbar a:hover,
.navbar a.active {
  text-decoration: none;
  color: #085f2d;  
  border-bottom: 2px solid #085f2d; /* Linha no hover/ativo */
}

/* Responsividade */
@media (max-width: 768px) {
  .menu-toggle {
    display: block;
  }

  .navbar-container {
    position: absolute;
    top: 150px; /* Alinha o menu abaixo do header */
    right: 0;
    background: #ffffff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
    z-index: 9;
  }

  .navbar-container.open {
    max-height: 300px; /* Altura suficiente para mostrar o menu */
  }

  .navbar {
    flex-direction: column;
    align-items: center;
    padding: 10px 0; /* Adiciona espaçamento vertical */
  }

  .navbar li {
    margin: 15px 0; /* Espaçamento mais generoso entre itens */
  }

  .navbar a {
    width: 100%; /* Links ocupam toda a largura */
    text-align: center; /* Centraliza o texto */
    font-size: 1.2rem; /* Aumenta a fonte para legibilidade */
    padding: 10px 0; /* Adiciona espaço interno */
  }
}
