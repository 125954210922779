footer {
    font-family: 'Montserrat', sans-serif;
    background-color: #0f8e45;
    background-color: #1a1a1a; /* Preto fosco */
    color: #fff;
    text-align: center;
    padding: 30px 15px;
  }
  
  .footer-links {
    margin: 10px 0;
    display: flex; /* Alinha os links em linha */
    justify-content: center; /* Centraliza os links */
    gap: 15px; /* Adiciona espaçamento entre os links */
}

.footer-links a {
    font-size: 14px;
    font-weight: 500;
    color: #666;
    transition: color 0.3s ease;
    text-decoration: none; /* Remove sublinhado */
    padding: 5px; /* Espaçamento interno opcional */
}

.footer-links a:hover {
    color: #d4d4d4;
}

  
  .social-icons {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    gap: 15px;
  }
  
  .social-icons a {
    color: #fff;
    font-size: 28px;
    transition: transform 0.3s ease, color 0.3s ease;
  }
  
  .social-icons a:hover {
    transform: scale(1.15);
    color: #b2f2bb;
  }
  
  .footer-rights {
    margin-top: 15px;
    font-size: 14px;
    font-weight: 400;
    color: #ddd;
  }
  
  .footer-rights p {
    margin: 0;
  }
  