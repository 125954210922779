body {
    margin: 0;
    font-family: 'Arial', sans-serif;
    background-color: #f4f4f4;
    color: #333;
  }
  
  .landing-page {
    text-align: center;
    padding: 20px;
  }
  
  /* Hero container com imagem e texto */
  .hero-container {
    position: relative;
    width: 95%;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .hero-image img {
    margin-top: 40px;
    width: 100%;
    height: auto;
    border-radius: 100px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  /* Conteúdo posicionado sobre a imagem */
  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    text-align: center;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    padding: 30px;
    max-width: 500px;
    width: 90%;
  }
  
  .hero-title {
    font-size: 2.5em;
    margin-bottom: 20px;
  }
  
  .benefits {
    text-align: left;
    font-size: 1.2em;
    line-height: 1.8;
    margin: 20px 0;
    list-style: none;
    padding: 0;
  }
  
  .benefits li {
    margin-bottom: 10px;
  }
  
  .cta-text {
    font-size: 1.1em;
    margin: 20px 0;
  }
  
  .cta-button {
    padding: 15px 30px;
    width: 300px;
    font-size: 1em;
    background-color: #2a9d8f;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-transform: uppercase;
  }
  
  .cta-button:hover {
    background-color: #1f776a;
  }
  
  /* FAQ */
  /* FAQ */
.faq {
    position: relative;
    padding: 30px 20px;
    background-color: #f9f9f9;
    margin: 40px auto;
    border-radius: 8px;
    max-width: 900px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif; /* Define uma fonte padrão para todo o FAQ */
    font-size: 1.1em; /* Tamanho de fonte consistente */
    line-height: 1.6; /* Linha mais espaçada para legibilidade */
    color: #333; /* Cor de texto padrão */
  }
  
  .faq h2 {
    font-size: 2em;
    color: #2a9d8f;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .faq ul,
  .faq ol {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .faq li {
    margin-bottom: 15px;
    font-weight: 400; /* Fonte regular */
  }
  
  .faq p {
    margin-bottom: 15px;
    font-weight: 400; /* Fonte regular */
  }
  
  /* FAQ Detalhes */
  details {
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 10px;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  details summary {
    font-size: 1.2em;
    font-weight: 600;
    color: #333;
    cursor: pointer;
    margin-bottom: 5px;
  }
  
  details summary:hover {
    color: #2a9d8f;
  }
  
  details p,
  details ul,
  details ol,
  details blockquote {
    margin-top: 10px;
    font-size: 1.1em;
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    color: #333;
  }
  
  
  /* Ajustes para Responsividade */
  @media (max-width: 768px) {
    .content {
      position: static;
      transform: none;
      padding: 20px;
      margin: 20px 0px;
      max-width: 100%;

    }
  
    .hero-title {
      font-size: 2em;
    }
  
    .cta-button {
      width: 100%;
      max-width: 300px;
    }
  
    .benefits {
      font-size: 1em;
    }
  
    .how-it-works h2,
    .testimonials h2,
    .faq h2 {
      font-size: 1.5em;
    }
  
    .how-it-works li,
    .testimonials blockquote,
    .faq li {
      font-size: 1em;
    }
  
    .faq {
      padding: 20px;
      margin: 20px 10px;
      max-width: 100%;
    }
  
    details summary {
      font-size: 1em;
    }
  
    details p,
    details ul,
    details ol,
    details blockquote {
      font-size: 0.9em;
    }
  }
  /* Estilizar o texto "Limpa Nome" para telas pequenas */
.mobile-title {
    display: none; /* Não aparece por padrão */
    position: absolute;
    top: 10%;
    left: 30%;
    transform: translate(-50%, -50%);
    color: #333;
    font-size: 1.5em;
    font-weight: bold;
    /* text-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4); */
    /* background: rgba(0, 0, 0, 0.5); */
    padding: 10px 20px;
    border-radius: 8px;
  }
  
  /* Exibir o texto somente em telas pequenas */
  @media (max-width: 768px) {
    .mobile-title {
      display: block;
    }
  }
  