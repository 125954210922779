/* Container principal */
#simular-credito {
  font-family: 'Montserrat', sans-serif;
  max-width: 1200px; /* Largura máxima */
  margin: 100px auto; /* Centraliza com espaçamento superior/inferior */
  margin-top: 40px;
  padding: 30px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Títulos */
#simular-credito h2 {
  text-align: left;
  font-size: 1.8rem; /* Destaca o título */
  color: #0f8e45; /* Verde principal */
  margin-bottom: 20px;
}

#simular-credito p {
  text-align: center;
  font-size: 1rem;
  color: #666;
  margin-bottom: 30px;
}

/* Linhas do formulário */
.form-row {
  display: flex;
  flex-wrap: wrap; /* Ajusta para telas menores */
  gap: 20px; /* Espaçamento entre campos */
  margin-bottom: 20px; /* Espaçamento entre linhas */
}

/* Grupos de formulário */
.form-row > .form-group {
  flex: 1 1 calc(50% - 20px); /* Ocupa 50% do espaço em telas maiores */
}

.form-group {
  display: flex;
  align-items: center; /* Alinha verticalmente no centro */
  gap: 10px; /* Espaçamento entre o label e o input */
}

.form-group label {
  flex-shrink: 0; /* Garante que o texto do label não encolha */
  font-weight: bold; /* Opcional: destaque no texto */
}

.form-group input {
  flex: 1; /* Faz o input ocupar o espaço restante */
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  color: #444;
}

/* Campos de entrada */
input,
textarea,
select {
  width: 100%;
  padding: 12px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s ease;
}

input:focus,
textarea:focus,
select:focus {
  border-color: #007bff; /* Destaque no campo ao focar */
  outline: none;
}

/* Radio buttons */
.radio-group {
  display: flex;
  gap: 15px;
  margin-top: 5px;
}

.radio-group label {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 1rem;
}

/* Botões */
button {
  width: 100%;
  padding: 12px;
  font-size: 1rem;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Botão enviar personalizado */
.btn-enviar {
  width: 200px;
  margin: 30px auto; /* Centraliza horizontalmente */
  display: block;
}

/* Campo de destaque */
.destaque {
  margin-bottom: 30px;
}

.destaque-valor {
  text-align: center;
}

.destaque-label {
  font-weight: bold;
  font-size: 1.2rem;
  color: #333;
}

.destaque-input {
  width: 100%;
  padding: 12px;
  border: 2px solid #ff9800;
  border-radius: 5px;
  font-size: 1rem;
  outline: none;
  transition: all 0.3s ease-in-out;
}

.destaque-input:focus {
  border-color: #ff5722;
  box-shadow: 0 0 8px rgba(255, 87, 34, 0.5);
}

.destaque-info {
  font-size: 0.9rem;
  color: #555;
}

/* Ajustes responsivos */
@media (max-width: 768px) {
  .form-row > .form-group {
    flex: 1 1 100%; /* Campos ocupam 100% da largura no mobile */
  }

  #simular-credito {
    padding: 20px;
  }

  #simular-credito h2 {
    font-size: 1.5rem; /* Reduz o título em telas menores */
  }

  button {
    font-size: 0.9rem;
    padding: 10px;
  }

  .btn-enviar {
    width: 100%; /* Botão ocupa toda a largura no mobile */
  }
}
