/* Container principal */
.solutions-container {
  margin-top: 100px;
  padding: 2rem;
  background-color: #f9f9f9;
  border-radius: 10px;

  text-align: center;
  min-height: 100vh;
  font-family: 'Montserrat', sans-serif;
  max-width: 1200px;  
  margin: 40px auto;
  margin-bottom: 40px;
}

/* Títulos */
.solutions-title {
  font-size: 1.9rem;
  color: #0f8e45; /* Verde principal */
  margin-bottom: 1rem;
  font-weight: 700;
  text-align: left;
}

.solutions-subtitle {
  font-size: 1rem;
  color: #666;
  margin-bottom: 2rem;
  font-weight: 400;
}

/* Botões de filtro */
.filter-buttons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  gap: 10px;
}

.filter-button {
  padding: 10px 20px;
  background-color: #fff;
  color: #0f8e45; /* Verde principal */
  border: 2px solid #0f8e45;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  transition: background-color 0.3s, color 0.3s;
  width: 200px;
  text-decoration: none; /* Remove decoração */
}

.filter-button:hover {
  background-color: #b08d57; /* Dourado no hover */
  color: #fff;
  text-decoration: none; /* Garantia extra */
}

.filter-button.active {
  background-color: #0f8e45;
  color: #fff;
}

/* Grid de soluções */
.solutions-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
}

/* Cards de solução */
.solution-card {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  text-align: center;
  position: relative;
  overflow: hidden;
  cursor: default;
  transition: transform 0.3s, box-shadow 0.3s;
  font-family: 'Montserrat', sans-serif;
  min-height: 300px; /* Altura mínima para padronizar os cards */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.solution-card:hover {
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}

/* Ícone e título */
.solution-icon {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.solution-title {
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
  color: #0f8e45; /* Verde principal */
}

/* Descrição da solução */
.solution-description {
  font-size: 1rem;
  color: #555; /* Cinza para texto */
  font-weight: 400;
  line-height: 1.5;
  flex-grow: 1;
}

/* Botão de ação (menor e visível apenas no hover) */
.quote-button {
  display: inline-block;
  margin-top: 1rem;
  padding: 8px 16px;
  background-color: #0f8e45; /* Verde principal */
  color: #fff;
  border: none;
  border-radius: 4px;
  text-decoration: none; /* Remove decoração */
  font-size: 0.9rem;
  font-weight: 500;
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.solution-card:hover .quote-button {
  opacity: 1;
  transform: translateY(0);
}

.quote-button:hover {
  background-color: #0c6e37; /* Tom mais escuro no hover */
  text-decoration: none; /* Garantia extra */
}

/* Exibir botão em dispositivos móveis */
@media (max-width: 768px) {
  .quote-button {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsividade para telas menores */
@media (max-width: 480px) {
  .solutions-grid {
    grid-template-columns: 1fr;
  }

  .solutions-title {
    font-size: 2rem;
  }

  .solution-icon {
    font-size: 2.5rem;
  }

  .solution-title {
    font-size: 1.3rem;
  }

  .filter-button {
    padding: 8px 15px;
    font-size: 0.9rem;
  }
}

/* Estilo geral para remover decoração de links e botões */
a,
button {
  text-decoration: none;
  outline: none;
}

.solutions-container a,
.solutions-container button {
  text-decoration: none;
}
