body {
  background: linear-gradient(135deg, #5e7960, #4d6c52, #3e5e45, #2e4f38);
  background-size: 400% 400%;
  animation: gradientAnimation 8s ease infinite;
  color: #ffffff;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}

/* Animação sutil no gradiente */
@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Botão Contato */
.btn-contato {
  width: 200px;
  margin: 0 auto;
}

/* Container Contato */
#contato {
  padding: 30px 40px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin: 40px auto;
  max-width: 1200px;
  text-align: center;
}

/* Títulos */
#contato h2 {
  font-family: "Montserrat", sans-serif;
  font-size: 1.8em;
  font-weight: 700;
  color: #0f8e45;
  margin-bottom: 20px;
  text-align: left;
}

/* Parágrafos */
#contato p {
  font-family: "Montserrat", sans-serif;
  font-size: 1em;
  color: #666;
  margin-bottom: 20px;
}

/* Informações de Contato */
.contact-info {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.contact-info p,
.contact-info a {
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  color: #333333;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 10px;
}

.contact-info img,
.contact-info svg {
  height: 20px;
  width: 20px;
  margin-right: 5px;
  vertical-align: middle;
}

.contact-info a {
  color: #0f8e45;
  text-decoration: none;
  font-weight: 500;
}

.contact-info a:hover {
  text-decoration: underline;
}

/* Botão WhatsApp */
.whatsapp-button {
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  font-size: 1em;
  background-color: #25d366;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.whatsapp-button:hover {
  background-color: #1da855;
  transform: scale(1.05);
}

/* Formulário */
#contact-form {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-group {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.form-group label {
  text-align: left;
  display: block;
  font-family: "Montserrat", sans-serif;
  font-size: 1em;
  font-weight: 500;
  color: #333;
  margin-bottom: 8px;
}

.form-group input,
.form-group textarea {
  font-family: "Montserrat", sans-serif;
  font-size: 1em;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 80%;
  box-sizing: border-box;
  transition: border-color 0.3s ease;
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: #0f8e45;
  outline: none;
}

/* Botão Enviar */
button[type="submit"] {
  font-family: "Montserrat", sans-serif;
  font-size: 1em;
  background-color: #0f8e45;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

button[type="submit"]:hover {
  background-color: #0c6d38;
  transform: scale(1.05);
}

/* Consentimento */
.form-group.consent {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  text-align: left;
}

.form-group.consent label {
  display: flex;
  align-items: center;
  gap: 5px;
}

/* Responsividade */
@media (max-width: 768px) {
  body {
    background-size: 300% 300%;
  }

  #contato {
    padding: 20px;
    margin: 100px auto;
    max-width: 90%;
  }

  #contato h2 {
    font-size: 1.5em;
    text-align: center;
  }

  #contato p {
    font-size: 0.9em;
  }

  .contact-info {
    flex-wrap: wrap;
    gap: 20px;
  }

  .whatsapp-button {
    font-size: 0.9em;
    padding: 8px 16px;
  }

  #contact-form {
    gap: 15px;
  }

  .form-group input,
  .form-group textarea {
    width: 100%;
    font-size: 0.9em;
  }

  button[type="submit"] {
    font-size: 0.9em;
    padding: 8px 16px;
  }
}

@media (max-width: 576px) {
  #contato {
    margin: 80px auto;
    padding: 20px;
  }

  .contact-info {
    gap: 15px;
  }

  .whatsapp-button {
    font-size: 0.8em;
    padding: 6px 12px;
  }

  .form-group input,
  .form-group textarea {
    font-size: 0.8em;
  }

  button[type="submit"] {
    font-size: 0.8em;
    padding: 8px 12px;
  }
}
